<template>
  <FocusTrap>
    <div class="card" id="mycard">
      <div class="card-header">
        <h6 class="mb-0">Bank Account Info</h6>
      </div>

      <div class="card-body">
        <form action="#">
          <div class="mb-3">
            <label class="form-label">Bank Name:</label>
            <input ref="txtname" type="text" class="form-control" placeholder="Bank Name" autocomplete="off" maxlength="50" v-model="bankinfo.bankname" autofocus required>
          </div>

          <div class="mb-3">
            <label class="form-label">Ac No:</label>
            <input type="number" class="form-control" placeholder="Bank Ac No"  maxlength="20" v-model="bankinfo.acno">
          </div>

          <div class="mb-3">
            <label class="form-label">Bank IFSC:</label>
            <input type="text" class="form-control text-uppercase" placeholder="IFSC"  maxlength="11" v-model="bankinfo.ifsc">
          </div>

          <div class="d-flex align-items-center">

            <div>
              <button type="button" class="btn btn-outline-success btn-labeled btn-labeled-start btn-sm" @click="perform_save_action">
                                        <span class="btn-labeled-icon bg-success text-white">
                                            <i class="ph-map-pin ph-sm"></i>
                                        </span>
                Update
              </button>
            </div>

<!--            <button class="btn btn-primary ms-3" @change="perform_save_action">  Save    . <i class="ph-paper-plane-tilt ms-2"></i></button>-->
          </div>
        </form>
      </div>
    </div>
  </FocusTrap>
</template>

<script>
import { store } from '@/store/store.js'
import { userService } from '@/store/auth-header.js'
export default {
  name: 'BankDetail',
  store,
  props: {
    mybank: {
      type: Object,
      required: false,
      default: JSON.parse('{"id":0,"cmp_id":1,"bankname":"","acno":"","ifsc":""}')
    },
    current_company_id : {
      type: Object,
      required: false,
      default: 0
    }
  },
  data () {
    return {
      bankinfo : {"id":0,"cmp_id":1,"bankname":"","acno":"","ifsc":""},
    }
  },
  mounted () {
    const self = this;

    this.$refs.txtname.focus();
  },
  methods: {
    closeWindow () {
      this.$emit('bankinfo_window_closed');
    },
    perform_save_action() {
      const self = this;
      try {

        if (self.bankinfo.bankname.trim().length == 0) {
          alert('Invalid Bank Name');
          return
        } else if  (self.bankinfo.acno.trim().length == 0) {
          alert('Invalid Ac No');
          return
        } else if  (self.bankinfo.ifsc.trim().length == 0) {
          alert('Invalid IFSC');
          return
        } else if  (self.$props.current_company_id == 0) {
          alert('Invalid Company ID');
          return
        }

        self.$data.bankinfo.cmp_id = self.$props.current_company_id;

        // console.log(JSON.stringify(self.bankinfo));

        const requestOptions = {
          method: (self.$data.bankinfo.id == 0 ? 'POST' : 'PUT'),
          mode: 'cors',
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.bankinfo)
        }

        $('#mycard').block({
          message: '<i class="icon-spinner2 spinner"></i>',
          overlayCSS: {
            backgroundColor: '#fff',
            opacity: 0.8,
            cursor: 'wait',
            'box-shadow': '0 0 0 1px #ddd'
          },
          css: {
            border: 0,
            padding: 0,
            backgroundColor: 'none'
          }
        });

        fetch(`${process.env.VUE_APP_ROOT_API}api/company_bankinfo`, requestOptions).then(userService.handleResponse).then(function (resp) {
          if (resp.ok) {
              self.bankinfo = resp.data;
              swal ( { title: "Success!" ,  text: resp.msg, type:  "success", timer:1500});
              self.$emit('bankinfo_saved');
          } else {
            swal({ title: 'Oops!', text: resp.msg, type: 'error', timer:3000 })
          }

        }).catch(function (err) {
          swal({ title: 'Oh noes!', text: err.toString(), type: 'error', timer:3000 })
        }).finally(function () {
          $('#mycard').unblock()
        });


      }catch (e) {
        alert(e);
      }
    }
  }
}
</script>

<style scoped>
  input:focus {
    background: #feff00;

  }

  textarea:focus {
    background: #feff00;

  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }
</style>
