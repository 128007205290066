<template>
  <FocusTrap>
    <div class="card">

    <div class="card-header header-elements-inline" style="background-color: whitesmoke;padding: 12px;" >
      <h5 class="card-title">Company Information</h5>
      <div class="header-elements">
        <div class="list-icons">
          <a class="list-icons-item" data-action="remove" @click="closeWindow"></a>
        </div>
      </div>
    </div>

    <div class="card-body">
      <p/>
      <form action="#">

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Name:</label>
          <div class="col-md-10">
            <input ref="txtname" class="form-control" v-if="company" v-model="company.name" autofocus required/>
          </div>
        </div>


        <div class="form-group row">
          <label class="col-md-2 col-form-label">Street:</label>
          <div class="col-md-10">
            <textarea class="form-control" v-if="company" v-model="company.street" rows="1"/>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Landmark:</label>
          <div class="col-md-10">
            <input class="form-control" v-if="company" v-model="company.landmark" rows="1"/>
          </div>
        </div>


        <div class="form-group row">
          <label class="col-md-2 col-form-label">City:</label>
          <div class="col-md-4">
            <input class="form-control" v-if="company" v-model="company.city" rows="3"/>
          </div>

          <label class="col-md-1 col-form-label">PIN:</label>
          <div class="col-md-5">
            <input  class="form-control" v-if="company" v-model="company.pin" />
          </div>
        </div>


        <div class="form-group row">
          <label class="col-md-2 col-form-label">Tel:</label>
          <div class="col-md-4">
            <input class="form-control" v-model="company.tel"/>
          </div>

          <label class="col-md-2 col-form-label">Mobile:</label>
          <div class="col-md-4">
            <input class="form-control" v-model="company.mobile"/>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">Email:</label>
          <div class="col-md-4">
            <input class="form-control" v-model="company.email"/>
          </div>

          <label class="col-md-2 col-form-label">Web:</label>
          <div class="col-md-4">
            <input class="form-control" v-model="company.web"/>
          </div>
        </div>

        <div class="form-group row">
          <label class="col-md-2 col-form-label">GSTIN:</label>
          <div class="col-md-4">
            <input class="form-control" v-if="company" v-model="company.gstin" maxlength="15" required/>
          </div>

          <label class="col-md-2 col-form-label">State:</label>
          <div class="col-md-4">
            <select data-placeholder="Select your state" class="form-control" v-if="company" v-model="company.state_cd" >
              <option value="37">Andhra Pradesh</option>
              <option value="35">Andaman and Nicobar Islands</option>
              <option value="12">Arunachal Pradesh</option>
              <option value="18">Assam</option>
              <option value="10">Bihar</option>
              <option value="04">Chandigarh</option>
              <option value="22">Chhattisgarh</option>
              <option value="26">Dadar and Nagar Haveli</option>
              <option value="25">Daman and Diu</option>
              <option value="07">Delhi</option>
              <option value="30">Goa</option>
              <option value="24">Gujarat</option>
              <option value="06">Haryana</option>
              <option value="02">Himachal Pradesh</option>
              <option value="01">Jammu and Kashmir</option>
              <option value="20">Jharkhand</option>
              <option value="29">Karnataka</option>
              <option value="32">Kerala</option>
              <option value="31">Lakshadweep</option>
              <option value="23">Madhya Pradesh</option>
              <option value="27">Maharashtra</option>
              <option value="14">Manipur</option>
              <option value="17">Meghalaya</option>
              <option value="15">Mizoram</option>
              <option value="13">Nagaland</option>
              <option value="21">Odisha</option>
              <option value="99">Over Seas</option>
              <option value="34">Pondicherry</option>
              <option value="03">Punjab</option>
              <option value="08">Rajasthan</option>
              <option value="11">Sikkim</option>
              <option value="33" selected>Tamil Nadu</option>
              <option value="36">Telangana</option>
              <option value="16">Tripura</option>
              <option value="09">Uttar Pradesh</option>
              <option value="05">Uttarakhand</option>
              <option value="19">West Bangal</option>
            </select>
          </div>
        </div>


      </form>
    </div>

    <div class="card-footer">
      <div class="text-right" >
        <button type="button" class="btn btn-primary" @click="saveDocument" >Save<i class="icon-paperplane ml-2"></i></button>
      </div>
    </div>
  </div>
  </FocusTrap>
</template>

<script>
  import {userService} from '@/store/auth-header.js'
  export default {
    name: 'CompanyForm',
    data () {
      return {
        company: JSON.parse('{"id":0,"type":0,"status":"Active","name":"","street":"","landmark":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":"","state_cd":33}'),
      }
    },
    props: {
      myitem: {
        type: Object,
        required: false,
        default: JSON.parse('{"id":0,"type":0,"status":"Active","name":"","street":"","landmark":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":"","state_cd":33}')
      }
    },
    beforeMount () {
      this.company = this.myitem; // save props data to itself's data and deal with tms
    },
    component: {

    },
    created () {
      this.$data.company = JSON.parse('{"id":0,"type":0,"status":"Active","name":"","street":"","landmark":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":"","state_cd":33}')
    },
    mounted () {
      const self = this;
      $('.form-control-select2').select2();

      this.$refs.txtname.focus();
    },
    methods: {
      closeWindow(){
        this.$emit('company_window_closed');
      },
      clear(){
        this.$data.company = JSON.parse('{"id":0,"type":0,"status":"Active","name":"","street":"","landmark":"","city":"","pin":"","gstin":"","tel":"","mobile":"","email":"","web":"","state_cd":33}')
      },
      saveDocument(){
        const self = this;

        self.$data.company.state_cd = parseInt(self.$data.company.state_cd);
        if(self.$data.company.name.trim().length < 1){
          alert("Invalid Name");
          return
        }else if(self.$data.company.state_cd <= 1){
          alert("Invalid State");
          return
        }


        const requestOptions = {
          method:  (self.$data.company.id == 0 ? 'POST' : 'PUT'),
          mode:"cors",
          headers: userService.authHeader(),
          body: JSON.stringify(self.$data.company)
        };


        fetch(`${process.env.VUE_APP_ROOT_API}api/company`,requestOptions).then(userService.handleResponse).then(function (resp) {
          if(resp.ok) {
            swal ( { title: "Success!" ,  text: resp.msg, type:  "success", timer:1500  });
            self.$emit('company_saved', resp.data);
          } else {
            swal ( { title: "Oops!" ,  text: resp.msg, type:  "error"} );
          }
        }).catch(function (err) {
          swal ( { title: "Oh noes!" ,  text: err.toString(), type:  "error"} );
        });
      }
    }
  }
</script>

<style scoped>
  input:focus {
    background: #feff00;
  }

  textarea:focus {
    background: #feff00;
  }

  select:focus {
    background: #feff00;
  }

  button:focus {
    background: #feff00;
    color: #0a0a0a!important;
  }

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  input[type=number] {
    -moz-appearance: textfield;
  }

</style>
